import React from 'react';
import PropTypes from 'prop-types';
import apiService from '../../services/api-service';
import Base from '../Base';
import Loading from '../Loading';

let initialState = {
  currentRoom: 0,
  currentItem: 0,
  reportDisagreements: false,
  roomDisagreementOverview: false,
  generalReview: false,
  askServices: false,
  currentColor: 0,
  colors: [
    'linear-gradient(rgba(85, 98, 14, 0.80), rgba(85, 98, 14, 0.90))',
    'linear-gradient(rgba(237, 165, 25, 0.80), rgba(237, 165, 25, 0.90))',
    'linear-gradient(rgba(95, 44, 61, 0.80), rgba(95, 44, 61, 0.90))',
    'linear-gradient(rgba(98, 77, 131, 0.80), rgba(98, 77, 131, 0.90))',
    'linear-gradient(rgba(34, 162, 199, 0.80), rgba(34, 162, 199, 0.90))',
  ],
  buttonsColors: [
    'linear-gradient(rgba(131, 148, 33, 0.80), rgba(131, 148, 33, 0.90))',
    'linear-gradient(rgba(237, 165, 25, 0.80), rgba(237, 165, 25, 0.90))',
    'linear-gradient(rgba(95, 44, 61, 0.80), rgba(95, 44, 61, 0.90))',
    'linear-gradient(rgba(98, 77, 131, 0.80), rgba(98, 77, 131, 0.90))',
    'linear-gradient(rgba(34, 162, 199, 0.80), rgba(34, 162, 199, 0.90))',
  ],
  availableServices: [],
  pwa_data: {},
};

class AppComponent extends React.Component {
  static propTypes = {
    setInitialState: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      imageTotal: null,
      imageLoaded: 0,
    };
  }

  async componentDidMount() {
    const { setInitialState } = this.props;
    let jsonUrl = null;

    // Get the url and generate the API url
    const pathName = window.location.pathname;
    const pathSearch = window.location.search;
    const apiUrl = pathName + pathSearch;

    // Save the api url on the initial state
    initialState = {
      ...initialState,
      apiUrl,
    };

    const { data: apiData } = await apiService.get(apiUrl);

    jsonUrl = apiData.data.url;
    const { uploadPath } = apiData;
    const availableServices = apiData.data.available_services;
    initialState = {
      ...initialState,
      availableServices,
      uploadPath,
    };
    const { data: jsonData } = await apiService.get(jsonUrl);
    initialState = {
      ...initialState,
      building: jsonData,
    };
    // Generate the Json to send based on the retrive Json
    const itemData = {
      description: '',
      evaluation: null,
      creationDate: '',
      damagePhotos: [],
    };
    const pwaData = {
      damages: {},
      services: [],
    };

    let count = 0;
    if (initialState.building) {
      Object.keys(initialState.building.building.rooms).forEach(room => {
        pwaData.damages[room] = itemData;
        Object.keys(initialState.building.building.rooms[room].items).forEach(item => {
          pwaData.damages[item] = itemData;
          // Preload images
          count += initialState.building.building.rooms[room].photos.length;
          Object.keys(initialState.building.building.rooms[room].photos).forEach(picture => {
            const img = new Image();
            img.onload = () => {
              this.handleLoadedImages();
            };
            img.src = initialState.building.building.rooms[room].photos[picture].filePath;
          });
          count += initialState.building.building.rooms[room].items[item].photos.length;
          Object.keys(initialState.building.building.rooms[room].items[item].photos).forEach(picture => {
            const img = new Image();
            img.onload = () => {
              this.handleLoadedImages();
            };
            img.src = initialState.building.building.rooms[room].items[item].photos[picture].filePath;
          });
        });
      });
      initialState.pwa_data = pwaData;

      setInitialState(initialState);

      this.setState({
        imageTotal: count,
      });

      if (count === 0) {
        this.setState({
          loading: false,
        });
      }
    }
  }

  handleLoadedImages = () => {
    this.setState(prevState => {
      const { imageTotal, loading } = prevState;
      let { imageLoaded } = prevState;

      let loadingFinished = false;
      if (loading && imageTotal !== null && ++imageLoaded >= imageTotal) loadingFinished = true;

      return { imageLoaded, loading: !loadingFinished };
    });
  };

  render() {
    const { imageLoaded, imageTotal, loading } = this.state;

    return loading ? <Loading current={imageLoaded} total={imageTotal} /> : <Base />;
  }
}

export default AppComponent;
